import {memo} from 'react';
import {useNavigate} from 'react-router-dom';
import {Menu, Transition} from '@headlessui/react';
import {useAuth} from '../../context/AuthContext';
import {Menu as MenuIcon} from 'lucide-react';
import logo from '../../../assets/assembledbrands_logo.png';

interface TopNavProps {
    onMenuToggle: () => void;
}

const TopNav = memo(({onMenuToggle}: TopNavProps) => {
    const navigate = useNavigate();
    const {user, logout} = useAuth();

    const handleLogout = async () => {
        await logout();
        navigate('/');
    };

    const handleChangePassword = async () => {
        navigate('/change-password');
    };

    return (
        <div
            className="fixed top-0 left-0 right-0 bg-white border-b border-gray-200 px-4 md:px-6 lg:px-8 py-3 md:py-4 flex justify-between items-center h-16 z-50">
            <div className="flex items-center space-x-3 md:space-x-6">
                <button className="p-2 hover:bg-gray-100 rounded-lg md:hidden" onClick={onMenuToggle}>
                    <MenuIcon size={24}/>
                </button>
                <img src={logo} alt="Logo" className="h-6 md:h-8"/>
            </div>

            <div className="flex items-center space-x-3 md:space-x-6">
                <button className="p-1.5 md:p-2 hover:bg-gray-100 rounded-full">
                    <svg className="w-4 h-4 md:w-5 md:h-5" fill="none" stroke="currentColor" viewBox="0 0 24 24">
                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2"
                              d="M15 17h5l-1.405-1.405A2.032 2.032 0 0118 14.158V11a6.002 6.002 0 00-4-5.659V5a2 2 0 10-4 0v.341C7.67 6.165 6 8.388 6 11v3.159c0 .538-.214 1.055-.595 1.436L4 17h5m6 0v1a3 3 0 11-6 0v-1m6 0H9"/>
                    </svg>
                </button>

                <Menu as="div" className="relative">
                    <Menu.Button className="flex items-center space-x-2 md:space-x-3">
                        <div
                            className="w-7 h-7 md:w-8 md:h-8 rounded-full bg-[#FB7C6A] flex items-center justify-center text-white text-sm md:text-base">
                            {user?.name?.[0]?.toUpperCase()}
                        </div>
                        <span className="hidden md:block">{user?.name}</span>
                    </Menu.Button>

                    <Transition
                        enter="transition duration-100 ease-out"
                        enterFrom="transform scale-95 opacity-0"
                        enterTo="transform scale-100 opacity-100"
                        leave="transition duration-75 ease-out"
                        leaveFrom="transform scale-100 opacity-100"
                        leaveTo="transform scale-95 opacity-0"
                    >
                        <Menu.Items
                            className="absolute right-0 mt-2 w-56 origin-top-right bg-white rounded-md shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                            <div className="p-2">
                                <div className="px-3 py-2">
                                    <p className="text-sm font-medium text-gray-900">{user?.name}</p>
                                    <p className="text-xs md:text-sm text-gray-500">{user?.email}</p>
                                    <p className="text-xs md:text-sm text-gray-500">Company: {(user as {
                                        'custom:company_name'?: string;
                                    })?.['custom:company_name']}</p>
                                </div>
                                <Menu.Item>
                                    {({active}) => (
                                        <button
                                            onClick={handleLogout}
                                            className={`${active ? 'bg-gray-100' : ''} w-full text-left px-3 py-2 text-sm text-red-600 rounded-md`}
                                        >
                                            Log out
                                        </button>
                                    )}
                                </Menu.Item>
                                <Menu.Item>
                                    {({active}) => (
                                        <button
                                            onClick={handleChangePassword}
                                            className={`${active ? 'bg-gray-100' : ''} w-full text-left px-3 py-2 text-sm text-red-600 rounded-md`}
                                        >
                                            Change password
                                        </button>
                                    )}
                                </Menu.Item>
                            </div>
                        </Menu.Items>
                    </Transition>
                </Menu>
            </div>
        </div>
    );
});


export default TopNav;
