import { memo } from 'react';
import { useLocation } from 'react-router-dom';
import { Home, Link2, Network, X } from 'lucide-react';
import NavItem from "./NavItem";

interface LeftNavProps {
    isOpen: boolean;
    onClose: () => void;
}

const LeftNav = memo(({ isOpen, onClose }: LeftNavProps) => {
    const location = useLocation();

    return (
        <>
            {isOpen && (
                <div
                    className="fixed inset-0 bg-black bg-opacity-50 z-40 md:hidden"
                    onClick={onClose}
                />
            )}
            <aside className={`
                fixed md:relative w-64 bg-[#2D2D2D]
                min-h-screen md:min-h-[100vh]
                transition-transform duration-300 ease-in-out z-40
                ${isOpen ? 'translate-x-0' : '-translate-x-full md:translate-x-0'}
            `}>
                <div className="flex justify-end p-4 md:hidden">
                    <button onClick={onClose} className="text-white">
                        <X size={24} />
                    </button>
                </div>

                <nav className="flex flex-col py-8">
                    <NavItem
                        to="/home"
                        icon={<Home className="stroke-current" size={20} />}
                        text="Home"
                        isActive={location.pathname === '/home'}
                    />
                    <NavItem
                        to="/integrations"
                        icon={<Link2 className="stroke-current" size={20} />}
                        text="Integrations"
                        isActive={location.pathname === '/integrations'}
                    />
                    <NavItem
                        to="/connections"
                        icon={<Network className="stroke-current" size={20} />}
                        text="Connections"
                        isActive={location.pathname === '/connections'}
                    />
                </nav>
            </aside>
        </>
    );
});

LeftNav.displayName = 'LeftNav';

export default LeftNav;
