import './App.css'
import DashboardLayout from "./components/authenticated-app/layout/DashboardLayout";
import Home from "./components/authenticated-app/layout/pages/Home";
import Integrations from "./components/authenticated-app/layout/pages/Integration";
import Connections from "./components/authenticated-app/layout/pages/Connections";
import {Route, Routes} from "react-router-dom";
import Login from "./components/unauthenticated-app/pages/Login/Login";
import SignUp from "./components/unauthenticated-app/pages/SignUp/SignUp";
import Verify from "./components/unauthenticated-app/pages/Verify/Verify";
import {ProtectedRoute} from "./components/ProtectedRoute/ProtectedRoute";
import FirstIntegration from "./components/authenticated-app/integration/FirstIntegration";
import UnAuthenticatedHome from "./components/unauthenticated-app/pages/UnAuthenticatedHome/UnAuthenticatedHome";
import ChangePassword from "./components/authenticated-app/ChangePassword/ChangePassword";

function App() {

    return (
        <>
            <Routes>
                <Route path="/" element={<UnAuthenticatedHome/>} />
                <Route path="/login" element={<Login />} />
                <Route path="/signup" element={<SignUp />} />
                <Route path="/verify" element={<Verify />} />
                <Route path="/first-integration" element={<ProtectedRoute><FirstIntegration /></ProtectedRoute>} />
                <Route path="/change-password" element={<ProtectedRoute><ChangePassword /></ProtectedRoute>} />

                <Route path="/home" element={<ProtectedRoute><DashboardLayout><Home/></DashboardLayout></ProtectedRoute>} />
                <Route path="/integrations" element={<ProtectedRoute><DashboardLayout><Integrations/></DashboardLayout></ProtectedRoute>} />
                <Route path="/connections" element={<ProtectedRoute><DashboardLayout><Connections/></DashboardLayout></ProtectedRoute>} />
            </Routes>
        </>
    )
}

export default App;
