import { memo, useState } from 'react';
import TopNav from "./TopNav";
import LeftNav from "./LeftNav";

interface DashboardLayoutProps {
    children: React.ReactNode;
}

function DashboardLayout({ children }: DashboardLayoutProps) {
    const [isMenuOpen, setIsMenuOpen] = useState(false);

    return (
        <div className="min-h-screen">
            <TopNav onMenuToggle={() => setIsMenuOpen(!isMenuOpen)} />
            <div className="flex mt-16">
                <LeftNav isOpen={isMenuOpen} onClose={() => setIsMenuOpen(false)} />
                <div className="flex-1 bg-[#faf7f4] w-full">
                    <main className="p-4 md:p-6 lg:p-8">{children}</main>
                </div>
            </div>
        </div>
    );
}
export default memo(DashboardLayout);
