import React, {useState, useEffect} from 'react';
import {Link} from "react-router-dom";
import {fetchAuthSession, getCurrentUser} from 'aws-amplify/auth';
import logo from '../../../assets/assembledbrands_logo.png';
import smileWoman from '../../../assets/home/smile-woman.jpg';
import {ConnectionCallbackArgs} from "@codat/sdk-link-types";
import {useAuth} from "../../context/AuthContext";
import {CodatLink} from "./CodatLink";
import Modal from "../modals/Modal";

const apiUrl = process.env.REACT_APP_API_URL;

function FirstIntegration() {
    const {user} = useAuth();
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState<string>('');
    const [showLinkComponent, setShowLinkComponent] = useState(false);
    const [companyId, setCompanyId] = useState('');
    const [mounted, setMounted] = useState(false);

    useEffect(() => {
        setMounted(true);
    }, []);

    const closeCodatLink = (): void => {
        setLoading(false);
        setShowLinkComponent(false);
    };

    const onConnection = (connection: ConnectionCallbackArgs): void =>
        console.log(`On connection callback - ${connection.connectionId}`);
    const onClose = (): void => {
        closeCodatLink();
    };
    const onFinish = (): void => {
        closeCodatLink();
    };
    const onError = (): void => {
        closeCodatLink();
    };

    const openCodatLink = async () => {
        setLoading(true);
        setError('');

        try {
            const session = await fetchAuthSession();
            const token = session.tokens?.idToken?.toString();

            if (!token) {
                throw new Error('No authentication token available');
            }

            const response = await fetch(`${apiUrl}/api/codat`, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': `Bearer ${token}`
                },
                credentials: 'include'
            });

            if (!response.ok) {
                const errorData = await response.text();
                throw new Error(errorData || 'Failed to fetch company ID');
            }

            const companyId = await response.text();
            setCompanyId(companyId);
            setShowLinkComponent(true);

            await getCurrentUser();
            await fetchAuthSession({ forceRefresh: true });

        } catch (err) {
            console.error('integration error:', err);
            setError(err instanceof Error ? err.message : 'Failed to connect to the server');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-[#faf7f4] flex items-center justify-center p-4">
            <div className={`bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-5xl flex transform transition-all duration-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <div className={`hidden md:block w-1/2 transition-all duration-1000 delay-300 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'}`}>
                    <img
                        src={smileWoman}
                        alt="Business handshake"
                        className="object-cover w-full h-full"
                    />
                </div>

                <div className={`w-full md:w-1/2 flex flex-col justify-center px-12 py-8 bg-[#F8F1E4] transition-all duration-1000 delay-500 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}>
                    <div className="max-w-md w-full mx-auto">
                        {/* Logo */}
                        <div className={`flex justify-center mb-8 transition-all duration-700 delay-700 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'}`}>
                            <img
                                src={logo}
                                alt="Assembled Brands Logo"
                                className="h-16 w-auto"
                            />
                        </div>

                        {/* Progress indicator */}
                        <div className={`mb-8 transition-all duration-700 delay-800 ${mounted ? 'opacity-100 scale-100' : 'opacity-0 scale-95'}`}>
                            <div className="text-sm text-gray-600 mb-2">STEP 2 OF 5</div>
                            <div className="h-1 bg-gray-200 rounded overflow-hidden">
                                <div
                                    className="h-1 bg-[#F4A798] rounded transition-all duration-1000 ease-out"
                                    style={{width: mounted ? '40%' : '0%'}}
                                ></div>
                            </div>
                        </div>

                        {/* Welcome Message */}
                        <div className={`mb-8 transition-all duration-700 delay-900 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <h2 className="text-xl font-bold text-black mb-4">
                                WELCOME, {user?.name}!
                            </h2>
                            <p className="text-gray-600 mb-6">
                                You're almost there! Complete the remaining steps to apply for your business loan.
                            </p>
                        </div>

                        {/* Error message */}
                        {error && (
                            <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded animate-shake">
                                {error}
                            </div>
                        )}

                        {/* Steps List */}
                        <div className={`space-y-4 mb-8 transition-all duration-700 delay-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div className="flex items-center justify-between transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <span className="text-gray-800">Create your Company's account</span>
                                <svg className="h-5 w-5 text-green-600 transform transition-transform duration-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div className="flex items-center justify-between transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <span className="text-gray-800">Company Information</span>
                                <svg className="h-5 w-5 text-green-600 transform transition-transform duration-500" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                    <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 13l4 4L19 7"/>
                                </svg>
                            </div>
                            <div className="text-gray-600 transition-all duration-300 hover:transform hover:scale-[1.02]">
                                Integrate and Upload Accounting Data
                            </div>
                            <div className="text-gray-600 transition-all duration-300 hover:transform hover:scale-[1.02]">
                                Integrate and Upload Commerce Data
                            </div>
                            <div className="text-gray-600 transition-all duration-300 hover:transform hover:scale-[1.02]">
                                Integrate and Upload Banking Data
                            </div>
                        </div>

                        {/* Buttons */}
                        <div
                            className={`flex justify-between items-center transition-all duration-700 delay-1100 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <Link
                                to="/home"
                                className="underline text-gray-600 font-medium transition-colors duration-300 hover:text-gray-900"
                            >
                                SAVE & CONTINUE LATER
                            </Link>
                            <button
                                onClick={openCodatLink}
                                disabled={loading}
                                className="bg-black text-white py-2 px-6 rounded-full hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-black flex items-center disabled:opacity-50 disabled:cursor-not-allowed transition-all duration-300 transform hover:scale-105"
                            >
                                {loading ? 'PROCESSING...' : 'COMPANY INTEGRATION'}
                                <svg
                                    className="ml-2 h-5 w-5 transition-transform duration-300 group-hover:translate-x-1"
                                    viewBox="0 0 20 20"
                                    fill="currentColor"
                                >
                                    <path
                                        fillRule="evenodd"
                                        d="M7.293 14.707a1 1 0 010-1.414L10.586 10 7.293 6.707a1 1 0 011.414-1.414l4 4a1 1 0 010 1.414l-4 4a1 1 0 01-1.414 0z"
                                        clipRule="evenodd"
                                    />
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            </div>

            <Modal isOpen={showLinkComponent}>
                <CodatLink
                    companyId={companyId}
                    onConnection={onConnection}
                    onError={onError}
                    onClose={onClose}
                    onFinish={onFinish}
                    width={"460px"}
                    height={"800px"}
                    maxHeight={"95%"}
                />
            </Modal>
        </div>
    );
}

export default FirstIntegration;