import {useEffect, useState} from 'react';
import {fetchAuthSession} from 'aws-amplify/auth';
import {ConnectionManagement} from "../../integration/ConnectionManagement";

const apiUrl = process.env.REACT_APP_API_URL;

function Connections() {
    const [error, setError] = useState<string>('');
    const [accessToken, setAccessToken] = useState('');

    useEffect(() => {
        const initializeConnections = async () => {
            setError('');

            try {
                const session = await fetchAuthSession();
                const token = session.tokens?.idToken?.toString();

                if (!token) {
                    throw new Error('No authentication token available');
                }

                const response = await fetch(`${apiUrl}/api/codat`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    credentials: 'include'
                });

                if (!response.ok) {
                    const errorData = await response.text();
                    throw new Error(errorData || 'Failed to fetch access token');
                }

                const companyId = await response.text();

                const response2 = await fetch(`${apiUrl}/api/codat/${companyId}/accessToken`, {
                    method: 'GET',
                    headers: {
                        'Content-Type': 'application/json',
                        'Authorization': `Bearer ${token}`
                    },
                    credentials: 'include'
                });
                setAccessToken(await response2.text());
            } catch (err) {
                console.error('Connections error:', err);
                setError(err instanceof Error ? err.message : 'Failed to connect to the server');
            }
        };
        initializeConnections();
    }, []);

    return (
        <div className="h-full w-full flex flex-col">
            {error && (
                <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded">
                    {error}
                </div>
            )}

            <div className="flex-1 w-full h-full min-h-[500px]">
                {accessToken ? (
                    <ConnectionManagement accessToken={accessToken}/>
                ) : (
                    <div className="flex-1 flex items-center justify-center">
                        <p>Initializing...</p>
                    </div>
                )}
            </div>
        </div>
    );
}

export default Connections;
