import React from 'react';

const HowOurAppWorks = () => {
    const steps = [
        {
            title: 'Introduce Your Business',
            description: 'Answer a few basic questions about your business so we can get a better understanding of your financing needs.',
            hexagonStyle: 'bg-white border-2 border-black'
        },
        {
            title: 'Connect Accounts',
            description: 'Connect your accounting and ecommerce accounts for a seamless experience.',
            hexagonStyle: 'bg-gray-300 border-2 border-black'
        },
        {
            title: 'Our Team Gets to Work',
            description: 'Submit your application and our team will get to work right away!',
            hexagonStyle: 'bg-gray-800 border-2 border-black'
        }
    ];

    return (
        <section className="py-16 bg-[#FDF8F4]">
            <div className="container mx-auto px-4">
                <h2 className="text-4xl font-bold text-center mb-24">
                    How Our Application Works
                </h2>

                <div className="relative flex flex-col md:flex-row justify-between max-w-[1400px] mx-auto px-4 md:px-20">
                    <div className="absolute hidden md:block top-[22px] left-[20%] right-[20%] h-[2px] bg-black"></div>

                    <div className="absolute md:hidden w-[2px] h-[65%] bg-black left-[39px] top-[8%]"></div>

                    {steps.map((step, index) => (
                        <div key={index} className="relative flex flex-row md:flex-col items-start md:items-center md:w-1/3 mb-12 md:mb-0">
                            <div className={`w-12 h-12 ${step.hexagonStyle} rotate-45 md:mb-20 z-10 flex-shrink-0`}></div>

                            <div className="ml-8 md:ml-0">
                                <h3 className="text-2xl font-semibold md:text-center mb-6 text-gray-800">
                                    {step.title}
                                </h3>

                                <p className="text-lg text-gray-600 md:text-center max-w-[300px] leading-relaxed">
                                    {step.description}
                                </p>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </section>
    );
};

export default HowOurAppWorks;