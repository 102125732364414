import { useState, useEffect } from 'react';
import assembledBrandsLogo from '../../../../../assets/assembledbrands_logo.png';

const TopNav = () => {
    const [scrolled, setScrolled] = useState(false);

    useEffect(() => {
        const handleScroll = () => {
            const offset = window.scrollY;
            if (offset > 50) {
                setScrolled(true);
            } else {
                setScrolled(false);
            }
        };

        window.addEventListener('scroll', handleScroll);
        return () => {
            window.removeEventListener('scroll', handleScroll);
        };
    }, []);

    return (
        <>
            <div className={`${scrolled ? 'h-[52px] bg-[#f8f1e4]' : 'h-[76px] bg-[#f8f1e4]'}`}></div>

            <header
                className={`fixed top-0 w-full z-50 transition-all duration-300 ease-in-out 
                ${scrolled ? 'py-2 bg-white' : 'py-6 bg-white'}`}
            >
                <div className="container mx-auto px-4">
                    <nav className="flex items-center justify-between">
                        <img
                            src={assembledBrandsLogo}
                            alt="Logo"
                            className={`transition-all duration-300 ${scrolled ? 'w-24' : 'w-32'}`}
                        />
                    </nav>
                </div>
            </header>
        </>
    );
};

export default TopNav;