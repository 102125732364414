import {Link, useNavigate} from "react-router-dom";
import {LucideEye, LucideEyeOff} from 'lucide-react';
import React, {useState, FormEvent, ChangeEvent, useEffect} from 'react';
import singUp from "../../../../assets/signup.jpg";
import logo from '../../../../assets/assembledbrands_logo.png';
import {signUp} from 'aws-amplify/auth';

interface FormData {
    companyName: string;
    fullName: string;
    email: string;
    password: string;
    repeatPassword: string;
    termsAccepted: boolean;
}

function SignUp() {
    const navigate = useNavigate();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [mounted, setMounted] = useState<boolean>(false);

    const [formData, setFormData] = useState<FormData>({
        companyName: '',
        fullName: '',
        email: '',
        password: '',
        repeatPassword: '',
        termsAccepted: false
    });

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        const value = e.target.type === 'checkbox' ? e.target.checked : e.target.value;
        setFormData({
            ...formData,
            [e.target.id]: value
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        if (formData.password !== formData.repeatPassword) {
            setError('Passwords do not match');
            setLoading(false);
            return;
        }

        if (formData.password.length < 8) {
            setError('Password must be at least 8 characters long');
            setLoading(false);
            return;
        }

        if (!formData.termsAccepted) {
            setError('You must accept the terms and conditions');
            setLoading(false);
            return;
        }

        try {
            const {isSignUpComplete, userId, nextStep} = await signUp({
                username: formData.email,
                password: formData.password,
                options: {
                    userAttributes: {
                        name: formData.fullName,
                        email: formData.email,
                        updated_at: `${new Date().getTime()}`,
                        'custom:company_name': formData.companyName,
                        'custom:roles': JSON.stringify(['ADMIN'])
                    }
                }
            });

            console.log('Sign up successful:', {isSignUpComplete, userId, nextStep});
            navigate('/verify', {
                state: {email: formData.email}
            });
        } catch (error) {
            console.error('Error in signup:', error);
            setError(error instanceof Error ? error.message : 'An error occurred during sign up');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-[#faf7f4] flex items-center justify-center p-4">
            <div
                className={`bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-5xl flex transform transition-all duration-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <div
                    className={`hidden md:block w-1/2 transition-all duration-1000 delay-300 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'}`}>
                    <img
                        src={singUp}
                        alt="Business handshake"
                        className="object-cover w-full h-full"
                    />
                </div>

                <div
                    className={`w-full md:w-1/2 flex flex-col px-12 py-8 bg-[#F8F1E4] transition-all duration-1000 delay-500 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}>
                    <div
                        className={`flex justify-center mb-8 transition-all duration-700 delay-700 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'}`}>
                        <img
                            src={logo}
                            alt="Company Logo"
                            className="h-12 w-auto"
                        />
                    </div>

                    <div className="max-w-md w-full mx-auto">
                        <div
                            className={`mb-8 transition-all duration-700 delay-800 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <h2 className="text-xl font-bold text-black mb-2">
                                WELCOME TO ASSEMBLED BRANDS
                            </h2>
                            <p className="text-gray-600">Please fill the form below to sign up</p>
                        </div>

                        {error && (
                            <div
                                className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded animate-shake">
                                {error}
                            </div>
                        )}

                        <form onSubmit={handleSubmit}
                              className={`space-y-6 transition-all duration-700 delay-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="companyName" className="block text-sm font-medium text-gray-700">
                                    Company Name
                                </label>
                                <input
                                    type="text"
                                    id="companyName"
                                    value={formData.companyName}
                                    onChange={handleChange}
                                    placeholder="Enter your Company Name"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                    required
                                />
                            </div>

                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="fullName" className="block text-sm font-medium text-gray-700">
                                    Full Name
                                </label>
                                <input
                                    type="text"
                                    id="fullName"
                                    value={formData.fullName}
                                    onChange={handleChange}
                                    placeholder="Enter your Full Name"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                    required
                                />
                            </div>

                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter your email address"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                    required
                                />
                            </div>

                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder="Enter your Password"
                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center transition-opacity duration-300 hover:opacity-70"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword
                                            ? <LucideEye className="h-5 w-5 text-gray-400"/>
                                            : <LucideEyeOff className="h-5 w-5 text-gray-400"/>
                                        }
                                    </button>
                                </div>
                                <p className="mt-1 text-sm text-gray-500">
                                    Use at least 8 characters with a mix of letters, numbers, and symbols.
                                </p>
                            </div>

                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="repeatPassword" className="block text-sm font-medium text-gray-700">
                                    Repeat Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        type={showRepeatPassword ? "text" : "password"}
                                        id="repeatPassword"
                                        value={formData.repeatPassword}
                                        onChange={handleChange}
                                        placeholder="Repeat that password"
                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center transition-opacity duration-300 hover:opacity-70"
                                        onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                    >
                                        {showRepeatPassword
                                            ? <LucideEye className="h-5 w-5 text-gray-400"/>
                                            : <LucideEyeOff className="h-5 w-5 text-gray-400"/>
                                        }
                                    </button>
                                </div>
                            </div>

                            <div
                                className="flex items-center transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <input
                                    id="termsAccepted"
                                    type="checkbox"
                                    checked={formData.termsAccepted}
                                    onChange={handleChange}
                                    className="h-4 w-4 border-gray-300 rounded transition-all duration-300"
                                    required
                                />
                                <label htmlFor="termsAccepted" className="ml-2 text-sm text-gray-700">
                                    I agree to the <button
                                    className="underline font-medium hover:text-gray-900 transition-colors duration-300">TERMS
                                    & CONDITIONS</button>
                                </label>
                            </div>

                            <div className="lg:flex lg:items-center lg:justify-between">
                                <div className="flex items-center space-x-1">
                                    <span className="text-sm text-gray-600">Already have an account?</span>
                                    <div className="transition-all duration-300 hover:transform hover:scale-[1.05]">
                                        <Link to="/login"
                                              className="text-sm font-medium underline hover:text-gray-900 transition-colors duration-300">LOG
                                            IN</Link>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-32 float-right bg-white text-black py-2 px-6 rounded-full font-medium border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none active:shadow-none">
                                    {loading ? 'SIGNING UP...' : 'SIGN UP'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default SignUp;
