import { memo } from 'react';
import { Link } from 'react-router-dom';

interface NavItemProps {
    to: string;
    icon: JSX.Element;
    text: string;
    isActive: boolean;
}

const NavItem = memo(({ to, icon, text, isActive }: NavItemProps) => (
    <Link
        to={to}
        className={`flex items-center justify-start w-full px-4 md:px-6 py-3 md:py-4 text-sm transition-colors duration-200 ${
            isActive
                ? 'bg-[#FB7C6A] text-white'
                : 'text-[#FB7C6A] hover:bg-[#FB7C6A] hover:text-white'
        }`}
    >
        <div className="flex items-center space-x-3 md:space-x-4">
            <span className="w-5 h-5">{icon}</span>
            <span className="font-medium">{text}</span>
        </div>
    </Link>
));

NavItem.displayName = 'NavItem';

export default NavItem;