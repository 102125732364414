import { useNavigate } from "react-router-dom";
import { LucideEye, LucideEyeOff } from 'lucide-react';
import React, { useState, FormEvent, ChangeEvent, useEffect } from 'react';
import { updatePassword } from 'aws-amplify/auth';
import singUp from "../../../assets/signup.jpg";
import logo from '../../../assets/assembledbrands_logo.png';

interface FormData {
    oldPassword: string;
    newPassword: string;
    repeatPassword: string;
}

function ChangePassword() {
    const navigate = useNavigate();
    const [showOldPassword, setShowOldPassword] = useState<boolean>(false);
    const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
    const [showRepeatPassword, setShowRepeatPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [mounted, setMounted] = useState<boolean>(false);

    const [formData, setFormData] = useState<FormData>({
        oldPassword: '',
        newPassword: '',
        repeatPassword: ''
    });

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        if (formData.newPassword !== formData.repeatPassword) {
            setError('New passwords do not match');
            setLoading(false);
            return;
        }

        if (formData.newPassword.length < 8) {
            setError('New password must be at least 8 characters long');
            setLoading(false);
            return;
        }

        try {
            await updatePassword({
                oldPassword: formData.oldPassword,
                newPassword: formData.newPassword
            });

            console.log('Password updated successfully');
            navigate('/home');
        } catch (error) {
            console.error('Error updating password:', error);
            setError(error instanceof Error ? error.message : 'An error occurred while updating password');
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-[#faf7f4] flex items-center justify-center p-4">
            <div className={`bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-5xl flex transform transition-all duration-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <div className={`hidden md:block w-1/2 transition-all duration-1000 delay-300 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'}`}>
                    <img
                        src={singUp}
                        alt="Business handshake"
                        className="object-cover w-full h-full"
                    />
                </div>
                <div className={`w-full md:w-1/2 flex flex-col px-12 py-8 bg-[#F8F1E4] transition-all duration-1000 delay-500 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}>
                    <div className={`flex justify-center mb-8 transition-all duration-700 delay-700 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'}`}>
                        <img
                            src={logo}
                            alt="Company Logo"
                            className="h-12 w-auto"
                        />
                    </div>

                    <div className="max-w-md w-full mx-auto">
                        <div className={`mb-8 transition-all duration-700 delay-800 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <h2 className="text-xl font-bold text-black mb-2">
                                CHANGE YOUR PASSWORD
                            </h2>
                            <p className="text-gray-600">Please enter your current password and choose a new one</p>
                        </div>

                        {error && (
                            <div className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded animate-shake">
                                {error}
                            </div>
                        )}

                        <form onSubmit={handleSubmit} className={`space-y-6 transition-all duration-700 delay-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="oldPassword" className="block text-sm font-medium text-gray-700">
                                    Current Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        type={showOldPassword ? "text" : "password"}
                                        id="oldPassword"
                                        value={formData.oldPassword}
                                        onChange={handleChange}
                                        placeholder="Enter your current password"
                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center transition-opacity duration-300 hover:opacity-70"
                                        onClick={() => setShowOldPassword(!showOldPassword)}
                                    >
                                        {showOldPassword
                                            ? <LucideEye className="h-5 w-5 text-gray-400"/>
                                            : <LucideEyeOff className="h-5 w-5 text-gray-400"/>
                                        }
                                    </button>
                                </div>
                            </div>

                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="newPassword" className="block text-sm font-medium text-gray-700">
                                    New Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        type={showNewPassword ? "text" : "password"}
                                        id="newPassword"
                                        value={formData.newPassword}
                                        onChange={handleChange}
                                        placeholder="Enter your new password"
                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center transition-opacity duration-300 hover:opacity-70"
                                        onClick={() => setShowNewPassword(!showNewPassword)}
                                    >
                                        {showNewPassword
                                            ? <LucideEye className="h-5 w-5 text-gray-400"/>
                                            : <LucideEyeOff className="h-5 w-5 text-gray-400"/>
                                        }
                                    </button>
                                </div>
                                <p className="mt-1 text-sm text-gray-500">
                                    Use at least 8 characters with a mix of letters, numbers, and symbols.
                                </p>
                            </div>

                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="repeatPassword" className="block text-sm font-medium text-gray-700">
                                    Repeat New Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        type={showRepeatPassword ? "text" : "password"}
                                        id="repeatPassword"
                                        value={formData.repeatPassword}
                                        onChange={handleChange}
                                        placeholder="Repeat your new password"
                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center transition-opacity duration-300 hover:opacity-70"
                                        onClick={() => setShowRepeatPassword(!showRepeatPassword)}
                                    >
                                        {showRepeatPassword
                                            ? <LucideEye className="h-5 w-5 text-gray-400"/>
                                            : <LucideEyeOff className="h-5 w-5 text-gray-400"/>
                                        }
                                    </button>
                                </div>
                            </div>

                            <div className="flex justify-between items-center">
                                <button
                                    type="button"
                                    onClick={() => navigate(-1)}
                                    className="w-32 bg-white text-black py-2 px-6 rounded-full font-medium border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none active:shadow-none">
                                    BACK
                                </button>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-48 bg-white text-black py-2 px-6 rounded-full font-medium border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none active:shadow-none">
                                    {loading ? 'UPDATING...' : 'UPDATE PASSWORD'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ChangePassword;
