import React, {useState, FormEvent, ChangeEvent, useEffect} from 'react';
import {LucideEye, LucideEyeOff} from 'lucide-react';
import {Link, useNavigate} from "react-router-dom";
import {signIn, fetchUserAttributes} from 'aws-amplify/auth';
import logIn from '../../../../assets/login.jpg';
import logo from '../../../../assets/assembledbrands_logo.png';
import {useAuth} from "../../../context/AuthContext";

interface FormData {
    email: string;
    password: string;
}

function Login() {
    const navigate = useNavigate();
    const {logout} = useAuth();
    const [showPassword, setShowPassword] = useState<boolean>(false);
    const [loading, setLoading] = useState<boolean>(false);
    const [error, setError] = useState<string>('');
    const [mounted, setMounted] = useState<boolean>(false);

    const [formData, setFormData] = useState<FormData>({
        email: '',
        password: ''
    });
    const {setUser} = useAuth();

    useEffect(() => {
        setMounted(true);
    }, []);

    const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
        setFormData({
            ...formData,
            [e.target.id]: e.target.value
        });
    };

    const handleSubmit = async (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        setError('');
        setLoading(true);

        try {
            const {isSignedIn, nextStep} = await signIn({
                username: formData.email,
                password: formData.password,
            });

            if (isSignedIn) {
                const attributes = await fetchUserAttributes();
                const userData = {
                    email: attributes.email || '',
                    name: attributes.name,
                    'custom:company_name': attributes['custom:company_name'],
                };
                setUser(userData);
                sessionStorage.setItem('user', JSON.stringify(userData));
                navigate('/first-integration');
            } else if (nextStep.signInStep === 'CONFIRM_SIGN_UP') {
                navigate('/verify', {
                    state: {email: formData.email}
                });
            }
        } catch (error) {
            if (('' + error).includes('UserAlreadyAuthenticatedException')) {
                await logout();
                await handleSubmit(e);
            } else {
                setError(error instanceof Error ? error.message : 'An error occurred during login');
            }
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="min-h-screen bg-[#faf7f4] flex items-center justify-center p-4">
            <div
                className={`bg-white rounded-lg shadow-lg overflow-hidden w-full max-w-5xl flex transform transition-all duration-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                <div
                    className={`hidden md:block w-1/2 transition-all duration-1000 delay-300 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 -translate-x-10'}`}>
                    <img
                        src={logIn}
                        alt="Business handshake"
                        className="object-cover w-full h-full"
                    />
                </div>

                <div
                    className={`w-full md:w-1/2 flex flex-col justify-center px-12 py-8 bg-[#F8F1E4] transition-all duration-1000 delay-500 ${mounted ? 'opacity-100 translate-x-0' : 'opacity-0 translate-x-10'}`}>
                    <div className="max-w-md w-full mx-auto">
                        <div
                            className={`flex justify-center mb-8 transition-all duration-700 delay-700 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 -translate-y-10'}`}>
                            <img
                                src={logo}
                                alt="Company Logo"
                                className="h-16 w-auto"
                            />
                        </div>

                        <div
                            className={`mb-8 transition-all duration-700 delay-800 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <h2 className="text-xl font-bold text-black mb-2">
                                WELCOME TO ASSEMBLED BRANDS
                            </h2>
                            <p className="text-gray-600">Please Log In</p>
                        </div>

                        {error && (
                            <div
                                className="mb-4 p-3 bg-red-100 border border-red-400 text-red-700 rounded animate-shake">
                                {error}
                            </div>
                        )}

                        <form onSubmit={handleSubmit}
                              className={`space-y-6 transition-all duration-700 delay-1000 ${mounted ? 'opacity-100 translate-y-0' : 'opacity-0 translate-y-10'}`}>
                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="email" className="block text-sm font-medium text-gray-700">
                                    Email
                                </label>
                                <input
                                    type="email"
                                    id="email"
                                    value={formData.email}
                                    onChange={handleChange}
                                    placeholder="Enter your email address"
                                    className="mt-1 block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                    required
                                />
                            </div>

                            <div className="transition-all duration-300 hover:transform hover:scale-[1.02]">
                                <label htmlFor="password" className="block text-sm font-medium text-gray-700">
                                    Password
                                </label>
                                <div className="mt-1 relative">
                                    <input
                                        type={showPassword ? "text" : "password"}
                                        id="password"
                                        value={formData.password}
                                        onChange={handleChange}
                                        placeholder="Enter your Password"
                                        className="block w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition-all duration-300"
                                        required
                                    />
                                    <button
                                        type="button"
                                        className="absolute inset-y-0 right-0 pr-3 flex items-center"
                                        onClick={() => setShowPassword(!showPassword)}
                                    >
                                        {showPassword
                                            ? <LucideEye className="h-5 w-5 text-gray-400"/>
                                            : <LucideEyeOff className="h-5 w-5 text-gray-400"/>
                                        }
                                    </button>
                                </div>
                            </div>

                            <div>
                                <div className="flex items-center space-x-1">
                                    <span className="text-sm text-gray-600">Don't have an account yet?</span>
                                    <div className="transition-all duration-300 hover:transform hover:scale-[1.05]">
                                        <Link to="/signup"
                                              className="text-sm font-medium underline hover:text-gray-800 transition-colors duration-300">SIGN
                                            UP</Link>
                                    </div>
                                </div>
                                <button
                                    type="submit"
                                    disabled={loading}
                                    className="w-32 float-right bg-white text-black py-2 px-4 rounded-full font-medium border-2 border-black shadow-[4px_4px_0px_0px_rgba(0,0,0,1)] transition-all duration-200 hover:translate-x-[3px] hover:translate-y-[3px] hover:shadow-none active:shadow-none">
                                    {loading ? 'LOGGING IN...' : 'LOG IN'}
                                </button>
                            </div>
                        </form>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default Login;
